<template>
  <table class="table table-google table-highlight w-100">
    <thead>
      <tr>
        <th>Status</th>
        <th>Reference</th>
        <th>Date</th>
        <th>Brand</th>
        <th>Glass</th>
        <th>Wax</th>
        <th>Wick</th>
        <th>Additives</th>
        <th>Dyes</th>
        <th>Fragrances</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="candle in candles" :key="candle.id">
        <td class="row-fit">
          <candle-status-badge :status="candle.candle_status" class="w-100" />
        </td>
        <td>
          <div class="flex gap-2 items-center">
            <icon-star v-if="candle.is_favorite" :checked="candle.is_favorite" />
            <span>{{ candle.candle_reference }}</span>
          </div>
        </td>
        <td class="row-fit">{{ candle.candle_date | isodate }}</td>
        <td>{{ candle.brand_text }}</td>
        <td>{{ candle.glass_text }}</td>
        <td>{{ candle.wax_text }}</td>
        <td>
          <div class="flex gap-2">
            <span v-if="candle.wick_count > 1">
              {{ candle.wick_count }}x
            </span>
            <span>{{ candle.wick_text }}</span>
          </div>
        </td>
        <td>
          <div class="flex flex-col gap-2">
            <div
              v-for="additive in candle.additive"
              :key="additive.id"
              class="flex gap-4 justify-between"
            >
              <div>{{ additive.name_text }}</div>
              <div>{{ additive.pct_text }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="flex flex-col gap-2">
            <div
              v-for="dye in candle.dye"
              :key="dye.id"
            >
              <div>{{ dye.name_text }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="flex flex-col gap-2">
            <div
              v-for="fragrance in candle.fragrance"
              :key="fragrance.id"
              class="flex gap-4 justify-between"
            >
              <div>{{ fragrance.name_text }}</div>
              <div>{{ fragrance.pct_text }}</div>
            </div>
          </div>
        </td>
        <td class="row-fit">
          <div class="flex gap-2">
            <router-link
              :to="getCandleLink(candle)"
              class="btn btn-primary btn-sm"
              title="View"
            >
              View
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  </template>

<script>
import { getCandleLink } from '@/composables/candle';

const IconStar = () => import('@/components/global/IconStar.vue');
const CandleStatusBadge = () => import('@/components/candle/CandleStatusBadge.vue');

export default {
  name: 'DesktopTable',
  components: {
    IconStar,
    CandleStatusBadge,
  },
  props: {
    candles: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getCandleLink(candle) {
      return getCandleLink(candle, this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
